@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap);
:root{
  --background-black: #1e212aff;
  --chat-screen: #15191cff;
  --bluehover: #3044c9ff;
  --pdf: #FB5855;
  --zip: #484BA2;
  --xl: #1DB463;
  --gunmetal: #252c36;
  --icons: #4B505F;
  --typing: #8294AD;
  --font-white: #fff;

}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Nunito', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e212aff!important;
  background-color: var(--background-black)!important;
  color: #fff!important;
  color: var(--font-white)!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
	height: 5px;
	width: 5px;
	background: #1e212aff;
	background: var(--background-black);
  }
::-webkit-scrollbar-thumb {
	background: #252c36;
	background: var(--gunmetal);
  border-radius: 20px;
}

.nav_profile{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--bluehover);
    text-align: center;
    display: flex;
    align-items: center; 
    justify-content: center;
}
.icon_styles{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center; 
    justify-content: center;
    color: var(--icons);
    border-radius: 10px;
    font-size: 20px;
}
.icon_styles:hover, .active_icon{
    background-color: var(--bluehover);
    cursor: pointer;
    color: var(--font-white);
}
.border-right{
    border-right: 2px solid var(--icons);
}
.chatlist_profile{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center; 
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
}
.border-bottom{
    border-bottom: 2px solid var(--icons) !important;
}
.chat_profile{
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
}
.chat_profile_image{
    display: flex;
    margin-top: 20px;
    width: 100px;
    height: 100px;
    background-color: var(--icons);
    border-radius: 50%;
    justify-content: center;
    align-items: center; 
    font-size: 30px;
    font-weight: 600;
}
.chat_profile_chat_image{
    display: flex;
    width:  50px;
    height: 50px;
    background-color: var(--icons);
    border-radius: 50%;
    justify-content: center;
    align-items: center; 
    font-size: 14px;
    font-weight: 600;
}
.chat_chat_profile:hover .chat_profile_chat_image{
    background-color: #fff;
    color: #000;
}
.chat_profile_name{
    font-weight: 400;
    font-size: 22px;
    margin-top: 5px;
}
.chat_profile_status_active{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: green;
}
.chat_profile_status{
    display: flex;
    align-items: center;
}
.recent_chat_profile, .chat_chat_profile{
    display: flex;
    /* margin-top: 20px;    */
    padding: 10px;
    cursor: pointer;
}
.chat_chat_profile:hover, .active_chat_chat_profile{
    background-color: var(--gunmetal);
    border-radius: 10px 0px 0px 10px;
    

}
.recent_chat_profile_text{
    width: 70%;
    /* justify-content: start; */
    display: flex;
    align-items: center; 
    height: 40px;   
}
.recent_chat_profile_icons{
    text-align: center;
}
.recent_chat_profile_name{
    width: 70%;
    /* justify-content: start; */
    display: flex;
    flex-direction: column;
}
.chat_chat_profile_typing, .chat_chat_profile_time{
    color: var(--typing);
    align-items: center;
    cursor: pointer;
}
.chat_chat_profile_time{
    font-size: 12px;
}
.chat_list_container{
    overflow: none;
    overflow-y: scroll;
    max-height: 270px;
}
/* For main chat window */
.chat_screen{
    background-color: var(--chat-screen);
    border-radius: 15px;
}

/* Chat screen header */
.chat_screen_header{
    display: flex;
    flex-direction: row;
}
.chat_profile_chat_name{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: left; 
}

/* Chats of user */
.chat_screen_chat_container{
    overflow-y: auto;
    height: 440px;
    /* background-color: green; */
    margin: 0px;
    padding: auto;
}
.chat_screen_msg_left, .chat_screen_msg_right{
    display: flex;
    flex-direction: row;
}
.chat_screen_msg_right{
    flex-direction: row-reverse;
}

.chat_screen_chat_container_text{
    display: flex;
    flex-direction: column;
}
.chat_screen_msg_left .chat_screen_chat_container_text::after{
    content: '';
    width: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid var(--gunmetal);
    position: relative;
    top: -24px;
    left: 1px;
}
.chat_screen_chat_profile{
    background-color: var(--typing);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: flex-end;
}

.chat_screen_msg_left .chat_screen_chat_text{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; 
    background-color: var(--gunmetal);
    max-width: 80%;
    border-radius: 15px;
    padding: 14px;
    font-size: 16px;
}
.chat_screen_msg_right .chat_screen_chat_text{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; 
    background-color: var(--bluehover);
    max-width: 80%;
    border-radius: 15px;
    padding: 14px;
    font-size: 16px;
}
.chat_screen_msg_right .chat_screen_chat_container_text::after{
    content: '';
    width: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid var(--bluehover);
    position: relative;
    top: -24px;
    left: 375px;
}

.chat_screen_chat_time{
    font-size: 12px;
}

/* FOr Typing space below chat screen */
.chat_screen_typing_space{
    background-color: var(--gunmetal);
    width: 100%;
    height: 60px;
    border-radius: 15px;
    display: flex;
}
.chat_screen_typing_space_input{
    width: 70%;
    height: 50px;
    background-color: transparent;
    border: 0px solid #565656 !important;
    outline: none;
    color: var(--typing);
    padding: 7px;
}
.chat_media_media{
    display: flex;
}
.chat_media_image{
    width: 120px;
    height: 80px;
    border-radius: 8px;
    -webkit-margin-end: 15px;
            margin-inline-end: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    
}
.chat_media_image:nth-child(1){
    background-color: var(--gunmetal);
    
}
.chat_media_image:nth-child(2){
    background-color: var(--bluehover);
}
.chat_media_icon{
    font-size: 30px;
}
.chat_media_attach{
    display: flex;
    flex-direction: column;
}

.chat_media_attach_contain{
    display: flex;
    flex-direction: row;
    
}
.chat_media_attach_contain p{
    font-size: 14px;
}
.chat_media_media_icon{
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}
.chat_media_attach_contain:nth-child(1) .chat_media_media_icon{
    background-color: var(--pdf);
    
}
.chat_media_attach_contain:nth-child(2) .chat_media_media_icon{
    background-color: var(--zip);
}
.chat_media_attach_contain:nth-child(3) .chat_media_media_icon{
    background-color: var(--xl);
}

.scroll_down{
    background-color: var(--gunmetal);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center; 
    font-size: 16px;
}
