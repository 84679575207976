/* For main chat window */
.chat_screen{
    background-color: var(--chat-screen);
    border-radius: 15px;
}

/* Chat screen header */
.chat_screen_header{
    display: flex;
    flex-direction: row;
}
.chat_profile_chat_name{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: left; 
}

/* Chats of user */
.chat_screen_chat_container{
    overflow-y: auto;
    height: 440px;
    /* background-color: green; */
    margin: 0px;
    padding: auto;
}
.chat_screen_msg_left, .chat_screen_msg_right{
    display: flex;
    flex-direction: row;
}
.chat_screen_msg_right{
    flex-direction: row-reverse;
}

.chat_screen_chat_container_text{
    display: flex;
    flex-direction: column;
}
.chat_screen_msg_left .chat_screen_chat_container_text::after{
    content: '';
    width: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid var(--gunmetal);
    position: relative;
    top: -24px;
    left: 1px;
}
.chat_screen_chat_profile{
    background-color: var(--typing);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: flex-end;
}

.chat_screen_msg_left .chat_screen_chat_text{
    width: fit-content; 
    background-color: var(--gunmetal);
    max-width: 80%;
    border-radius: 15px;
    padding: 14px;
    font-size: 16px;
}
.chat_screen_msg_right .chat_screen_chat_text{
    width: fit-content; 
    background-color: var(--bluehover);
    max-width: 80%;
    border-radius: 15px;
    padding: 14px;
    font-size: 16px;
}
.chat_screen_msg_right .chat_screen_chat_container_text::after{
    content: '';
    width: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid var(--bluehover);
    position: relative;
    top: -24px;
    left: 375px;
}

.chat_screen_chat_time{
    font-size: 12px;
}

/* FOr Typing space below chat screen */
.chat_screen_typing_space{
    background-color: var(--gunmetal);
    width: 100%;
    height: 60px;
    border-radius: 15px;
    display: flex;
}
.chat_screen_typing_space_input{
    width: 70%;
    height: 50px;
    background-color: transparent;
    border: 0px solid #565656 !important;
    outline: none;
    color: var(--typing);
    padding: 7px;
}