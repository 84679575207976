@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
:root{
  --background-black: #1e212aff;
  --chat-screen: #15191cff;
  --bluehover: #3044c9ff;
  --pdf: #FB5855;
  --zip: #484BA2;
  --xl: #1DB463;
  --gunmetal: #252c36;
  --icons: #4B505F;
  --typing: #8294AD;
  --font-white: #fff;

}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Nunito', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-black)!important;
  color: var(--font-white)!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
	height: 5px;
	width: 5px;
	background: var(--background-black);
  }
::-webkit-scrollbar-thumb {
	background: var(--gunmetal);
  border-radius: 20px;
}